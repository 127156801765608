import { Component, h } from 'preact';
import { Router } from 'preact-router';
import "bulma/css/bulma.min.css"

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Todo from '../routes/todo';


class App extends Component {
	constructor() {
		super();
		this.dragging = {};
		this.state = {
			files: {
			}
		};
	}

	toggleItemDone = (e, filename, idx) => {
		
		this.saveFile(filename);
	}

	componentDidMount() {
		this.getLoginInfoFromLocalStorage();

		if(this.state.loginInfo) {
			this.loopRequests();
		}
	}

	getLoginInfoFromLocalStorage = () => {
		let loginInfo = localStorage.getItem("loginInfo")
		if(loginInfo) {
			this.state.loginInfo = JSON.parse(loginInfo);
		} else {
			this.state.showPWModal = true;
		}
		this.setState(this.state);
	}

	loopRequests = async () => {
	}

	savePW = async (e) => {
		e.preventDefault();
		let headers = new Headers();
		headers.set("username", e.target[0].value);
		headers.set("password", e.target[1].value);
		let resp = await fetch("/api/checklogin", {headers: headers});
		if(resp.status == 200) {
			this.state.loginInfo = {login: e.target[0].value, pw: e.target[1].value};
			this.state.showPWModal = false;
			localStorage.setItem("loginInfo", JSON.stringify(this.state.loginInfo))
		} else if(resp.status == 401) {
			this.state.badCredentials = true;
		}
		this.setState(this.state);
		return false;
	}

	render(props, state) {
		return (
			<div id="app">
				<Router>
					<Home path="/" 
						loginInfo={state.loginInfo}/>
					<Todo path="/todo/:listid" 
						loginInfo={state.loginInfo}/>
				</Router>
				<div class={state.showPWModal ? 'modal is-active' : 'modal'}>
				<div class="modal-background"></div>
				<div class="modal-content">
					<div class="box">
						<h2 class="title is-6">Enter login info</h2>
						<form onsubmit={this.savePW}>
							<div class="field">
								<input class="input" type="text" placeholder="Username"></input>
							</div>
							<div class="field">
								<input class="input" type="password" placeholder="Password"></input>
							</div>
							<div class="is-flex is-justify-content-space-between">
								{state.badCredentials ? <span class="has-text-danger is-align-self-center">Bad credentials</span> : <span></span>}
								<button type="submit" class="button is-success">OK</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			</div>
		)
	}
}


export default App;
